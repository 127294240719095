/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { formatDistanceToNowStrict } from "date-fns";
import "./layout.css";

const Layout = ({ children, date, isToday }) => (
  <>
    <div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: -1
        }}
      >
        <picture className="css-utffrj">
          <source
            media="(min-resolution: 192dpi) and (min-width: 1280px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 1280px)"
            srcSet="https://media-api.xogrp.com/images/9e906dc7-ab3f-4e14-bfe9-cad416cca43c~rt_auto-rs_3000.h?ordering=explicit"
          />
          <source
            media="(min-resolution: 192dpi) and (min-width: 1024px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 1024px)"
            srcSet="https://media-api.xogrp.com/images/9e906dc7-ab3f-4e14-bfe9-cad416cca43c~rt_auto-rs_2560.h?ordering=explicit"
          />
          <source
            media="(min-resolution: 192dpi) and (min-width: 768px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 768px)"
            srcSet="https://media-api.xogrp.com/images/9e906dc7-ab3f-4e14-bfe9-cad416cca43c~rt_auto-rs_2048.h?ordering=explicit"
          />
          <source
            media="(min-resolution: 192dpi), (-webkit-min-device-pixel-ratio: 2)"
            srcSet="https://media-api.xogrp.com/images/55efc0ee-2099-48d6-b62d-c0cdd1334443~rt_auto-rs_1536.h?ordering=explicit"
          />
          <source
            media="(min-resolution: 144dpi) and (min-width: 1280px), (-webkit-min-device-pixel-ratio: 1.5) and (min-width: 1280px)"
            srcSet="https://media-api.xogrp.com/images/9e906dc7-ab3f-4e14-bfe9-cad416cca43c~rt_auto-rs_2250.h?ordering=explicit"
          />
          <source
            media="(min-resolution: 144dpi) and (min-width: 1024px), (-webkit-min-device-pixel-ratio: 1.5) and (min-width: 1024px)"
            srcSet="https://media-api.xogrp.com/images/9e906dc7-ab3f-4e14-bfe9-cad416cca43c~rt_auto-rs_1920.h?ordering=explicit"
          />
          <source
            media="(min-resolution: 144dpi) and (min-width: 768px), (-webkit-min-device-pixel-ratio: 1.5) and (min-width: 768px)"
            srcSet="https://media-api.xogrp.com/images/9e906dc7-ab3f-4e14-bfe9-cad416cca43c~rt_auto-rs_1536.h?ordering=explicit"
          />
          <source
            media="(min-resolution: 144dpi), (-webkit-min-device-pixel-ratio: 1.5)"
            srcSet="https://media-api.xogrp.com/images/55efc0ee-2099-48d6-b62d-c0cdd1334443~rt_auto-rs_1152.h?ordering=explicit"
          />
          <source
            media="(min-width: 1280px)"
            srcSet="https://media-api.xogrp.com/images/9e906dc7-ab3f-4e14-bfe9-cad416cca43c~rt_auto-rs_1500.h?ordering=explicit"
          />
          <source
            media="(min-width: 1024px)"
            srcSet="https://media-api.xogrp.com/images/9e906dc7-ab3f-4e14-bfe9-cad416cca43c~rt_auto-rs_1280.h?ordering=explicit"
          />
          <source
            media="(min-width: 768px)"
            srcSet="https://media-api.xogrp.com/images/9e906dc7-ab3f-4e14-bfe9-cad416cca43c~rt_auto-rs_1024.h?ordering=explicit"
          />
          <source srcSet="https://media-api.xogrp.com/images/55efc0ee-2099-48d6-b62d-c0cdd1334443~rt_auto-rs_768.h?ordering=explicit" />
          <img
            loading="eager"
            alt=""
            src="https://media-api.xogrp.com/images/9e906dc7-ab3f-4e14-bfe9-cad416cca43c~rt_auto-rs_1024.h?ordering=explicit"
            className="css-1ago99h"
          />
        </picture>
      </div>
      <div style={{ textAlign: "center", padding:'0 16px' }}>
        <h1
          style={{
            lineHeight: "1",
            fontFamily: "Dancing Script",
            color: "#565458",
            fontSize: "60px",
            fontWeight: "400",
            margin: "16px 0",
            paddingTop: "80px",
            paddingBottom: "20px"
          }}
        >
          Janelle & Shawn
        </h1>
        <div
          style={{
            lineHeight: "1",
            fontFamily: "Source Serif Pro",
            color: "#565458",
            fontSize: "20px",
            fontWeight: "400",
            textTransform: "uppercase",
            letterSpacing: "2px",
            margin: "8px 0"
          }}
        >
          SEPTEMBER 12, 2021 • 11AM CST
          <div style={{ paddingTop: 8 }}>
            {isToday
              ? `TODAY!`
              : date
                ? `${formatDistanceToNowStrict(date, {
                  unit: "day"
                })} TO GO!`
                : ""}
          </div>
        </div>
      </div>
      <div style={{ margin: "auto", maxWidth: 1024, padding: "0 20px" }}>
        <main>{children}</main>
        <footer style={{ textAlign: "center", position: "fixed", width: "100%", bottom: 0, fontSize: 14 }}>
          © {new Date().getFullYear()} TheBigDay.Live
        </footer>
      </div>
    </div>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
